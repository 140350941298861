<template>
  <div>
    <el-row style="display: flex; justify-content: end">
      <i
        style="font-size: 30px; cursor: pointer"
        @click="
          () => {
            this.$router.back()
          }
        "
        class="el-icon-close"
      ></i>
    </el-row>
    <el-steps
      style="width: 100%"
      :active="isActive"
      finish-status="success"
      align-center
    >
      <el-step title="安排任课老师"></el-step>
      <el-step title="课程任务安排"></el-step>
      <el-step title="课程表预览"></el-step>
    </el-steps>
    <!-- 组件 -->
    <!-- @saveSuccess="saveSuccess" -->
    <component @saveBtnJump="saveBtnJump" :is="comList[isActive].com" ref="com"></component>
    <el-row style="display: flex; justify-content: center; margin-top: 20px">
      <el-button size="medium" type="primary" @click="saveBtn">保 存</el-button>
      <el-button size="medium" @click="backBtn">返 回</el-button>
    </el-row>
  </div>
</template>

<script>
import { debounce } from '@/utils/util.js'
export default {
  name: 'courseArrangement',
  components: {
    teacherArrangement: () => import('./teacherArrangement.vue'),
    taskArrangement: () => import('./taskArrangement.vue'),
    curriculum: () => import('./curriculum.vue'),
  },
  data() {
    return {
      isActive: 0,
      comList: [
        { name: '安排任课老师', com: 'teacherArrangement' },
        { name: '课程任务安排', com: 'taskArrangement' },
        { name: '课程表预览', com: 'curriculum' },
      ],
    }
  },
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
    await this.$store.dispatch('sessionDic')
  },
  mounted() {},
  methods: {
    // 调用子组件
    saveBtn: debounce(function () {
      this.$refs.com.saveBtn()
    }),
    // 保存
    saveBtnJump(){
      // this.$refs.com.saveBtn()
      if (this.isActive === 2) {
        this.$router.back()
      } else {
        this.isActive++
        if (this.isActive === 2) {
          this.$nextTick(() => {
            this.$refs.com.showClose = false
          })
        }
      }
    },
    // 返回
    backBtn: debounce(function () {
      if (!this.isActive) {
        this.$router.back()
      } else {
        this.isActive--
      }
    }, 300),
    // // 保存成功后回调
    // saveSuccess() {
    //   if (this.isActive == 2) {
    //     this.$router.back()
    //   } else {
    //     this.isActive++
    //   }
    // },
  },
  watch: {},
  filters: {},
}
</script>

<style lang="sass" scoped></style>
